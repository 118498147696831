import { template as template_b78558993b0045bdb12fa59e4af55c1a } from "@ember/template-compiler";
const FKControlMenuContainer = template_b78558993b0045bdb12fa59e4af55c1a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
